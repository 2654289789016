import StoreBadges from 'components/StoreBadges';
import React from 'react';
import './Header.css';

import mockUpImage from 'assets/images/gw_screenshot_updated.png';

const HeaderLogo = () => {
  return (
    <div className="text-center">
      <img
        className="h-auto w-64 inline-block"
        src={require('assets/images/gw-logo-white-horizontal.svg')}
        alt="GoodWork.ph"
      />
    </div>
  );
};

const Header = () => {
  return (
    <div className="bg-blue-gradient overflow-hidden">
      <div className="md:flex mx-auto align-start px-10 py-8">
        <HeaderLogo />
      </div>

      <div className="md:flex justify-center mx-4">
        <div className="text-center self-center mt-6 mb-8 md:my-16">
          <h2 className="text-white text-xl sm:text-3xl lg:text-4xl">
            #1 App for Home Services <br />
            Top 50 Startup <br className="xs:hidden md:inline lg:hidden" />
            in Southeast Asia
          </h2>
          <h3 className="mt-4 mb-2 text-white text-lg">Download now!</h3>
          <StoreBadges />
        </div>
        <div className="self-end relative text-center">
          <img
            className="mock-phone inline-block max-w-xs sm:max-w-md md:max-w-xs lg:max-w-md"
            src={mockUpImage}
            alt="Hand holding phone with GoodWork app on screen"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
