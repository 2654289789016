import React from 'react';

const FeaturedBy = () => {
  return (
    <div className="mx-auto my-12 md:my-16 bg-white">
      <h1 className="text-goodwork-navyblue font-bold mb-4 text-center md:text-lg">
        Featured By
      </h1>
      <div className="px-6 flex flex-wrap justify-center mx-auto md:max-w-2xl lg:max-w-3xl xl:max-w-none">
        <a
          className="block"
          href="https://www.techinasia.com/home-service-platform-goodwork-raises-16m-seed-funding"
        >
          <img
            className="w-24 lg:w-32 mx-6 mb-4"
            src={require('assets/images/techinasia-logo.png')}
            alt="Tech in Asia"
          />
        </a>
        <a
          className="block"
          href="https://techcrunch.com/2020/05/14/philippines-based-home-services-platform-goodwork-gets-1-6-million-to-expand-in-southeast-asia/"
        >
          <img
            className="w-24 lg:w-32 mx-6 md:mt-6 xs:mt-4 mb-4"
            src={require('assets/images/techcrunch-logo.png')}
            alt="TechCrunch"
          />
        </a>
        <a
          className="block"
          href="https://www.dealstreetasia.com/stories/goodwork-chaac-ventures-188707/"
        >
          <img
            className="w-24 lg:w-32 mx-6 mb-4"
            src={require('assets/images/dealstreet-logo.png')}
            alt="Deal Street Asia"
          />
        </a>
        <a
          className="block"
          href="https://rappler.com/brandrap/announcements/goodwork-home-wellness-services-app"
        >
          <img
            className="w-24 lg:w-32 mx-6 md:mt-6 xs:mt-4 mb-4"
            src={require('assets/images/rappler-logo.jpeg')}
            alt="Rappler"
          />
        </a>
        <a
          className="block"
          href="https://asia.nikkei.com/Business/Startups/Philippine-home-services-company-GoodWork-raises-1.6m"
        >
          <img
            className="w-24 lg:w-32 mx-6 mb-4"
            src={require('assets/images/nikkei-logo.png')}
            alt="Nikkei Asian Review"
          />
        </a>
        <a
          className="block"
          href="https://news.abs-cbn.com/ancx/culture/spotlight/07/14/20/need-a-plumber-your-aircon-cleaned-a-mani-pedi-this-guy-built-a-super-app-for-all-that-and-more"
        >
          <img
            className="w-24 lg:w-32 mx-6 xs:mt-1"
            src={require('assets/images/abs-logo.png')}
            alt="ABS-CBN"
          />
        </a>
      </div>
    </div>
  );
};

export default FeaturedBy;
