import React from 'react';
import './BestSellerSection.css';

const BestSellerSection = () => {
  return (
    <div className="sm:flex justify-center my-12 sm:mr-6">
      <div className="sm:self-end sm:flex-1 md:flex-none sm:pb-6 md:pb-0">
        <img
          className="mx-auto sm:max-w-xs md:max-w-sm h-auto"
          src={require('assets/images/gw_onboarding.png')}
          alt="a man standing and using GoodWork mobile app"
        />
      </div>

      <div className="service-pricing">
        <h1 className="mx-6 text-goodwork-navyblue font-semibold text-xl text-center sm:text-left mt-3 mb-2 md:mb-4">
          Best Sellers!
        </h1>
        <div className="max-w-sm mx-auto items-center border border-gray-300 rounded-md px-6 py-8 sm:py-6 shadow-md">
          <div>
            <h1 className="text-goodwork-blue font-bold">Condo Cleaning!</h1>
            <h2 className="text-goodwork-navyblue font-semibold">
              &#8369;399 for Studio
            </h2>
            <h2 className="text-goodwork-navyblue font-semibold">
              &#8369;449 for 1 Bedroom
            </h2>
            <div className="text-gray-600 mt-2">
              All materials and transpo already included.{' '}
              <br className="hidden xs:inline" />
              Cleaners all work directly for GoodWork.
            </div>
          </div>
          <div className="mt-6">
            <h1 className="text-goodwork-blue font-bold">Laundry Service!</h1>
            <h2 className="text-goodwork-navyblue font-semibold">
              &#8369;285 for 0-8kg
            </h2>
            <div className="text-gray-600 mt-2">
              Free pick-up and delivery. <br /> Clothes are returned within 48
              hours.
            </div>
          </div>
          <div className="mt-6">
            <h1 className="text-goodwork-blue font-bold">Beauty Services!</h1>
            <h2 className="text-goodwork-navyblue font-semibold">
              &#8369;328 for Mani-Pedi at home
            </h2>
            <div className="text-gray-600 mt-2">
              Our nail techs use top brands like HappySkin.{' '}
              <br className="hidden xs:inline" /> Enjoy at the comfort of{' '}
              <br className="xxs:hidden" />
              your home.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BestSellerSection;
