import React from 'react';
import StoreBadges from 'components/StoreBadges';

const DownloadLinks = () => (
  <div className="bg-gray-300 py-12 md:py-16">
    <p className="text-goodwork-blue mb-4 text-center text-lg sm:text-xl md:text-3xl lg:text-3xl leading-tight">
      &quot;GoodWork is the Home <br /> Service Super App&quot;
    </p>
    <p className="text-goodwork-navyblue text-center sm:text-lg md:text-2xl lg:text-2xl mt-2 mb-6 leading-tight  font-bold">
      Download the GoodWork <br className="sm:hidden" /> App to Book
    </p>
    <StoreBadges />
  </div>
);

export default DownloadLinks;
