import Footer from 'components/Footer';
import React from 'react';
import BestSellerSection from './components/BestSellerSection';
import DownloadLinks from './components/DownloadLinks';
import FeaturedBy from './components/FeaturedBy';
import Header from './components/Header';
import PerksSection from './components/PerksSection';
import Reviews from './components/Reviews';

const HomePage = () => {
  return (
    <>
      <Header />
      <PerksSection />
      <Reviews />
      <BestSellerSection />
      <FeaturedBy />
      <DownloadLinks />
      <Footer />
    </>
  );
};

export default HomePage;
