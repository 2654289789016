import storeLinks from 'constants/storeLinks';
import React from 'react';

function StoreBadges() {
  return (
    <div className="flex flex-wrap justify-center mx-auto max-w-xs md:max-w-md">
      {storeLinks.map((store) => (
        <a href={store.url} className="inline-block mx-1 mb-2" key={store.url}>
          <img
            src={store.imageSource}
            alt={store.altText}
            className="h-10 sm:h-12"
          />
        </a>
      ))}
    </div>
  );
}

export default StoreBadges;
