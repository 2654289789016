import ROUTES from 'constants/routes';
import storeLinks from 'constants/storeLinks';
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="nav-links-container py-12 md:py-16 px-6 xl:px-12 pb-8 sm:grid sm:grid-cols-2 md:grid-cols-5 xl:grid-cols-4 sm:gap-6 xl:gap-8">
      <div className="lg:max-w-xs md:col-span-2 xl:col-span-1 mt-4 sm:mt-0">
        <img
          src={require('assets/images/gw-logo-white-horizontal.svg')}
          alt="GoodWork.ph"
          className="w-48 my-2"
        />

        <p className="sm:text-sm max-w-xs text-white mb-6 xl:pr-6 leading-loose opacity-75">
          GoodWork is the #1 App for Home Services in the Philippines with the
          most affordable prices and the most trusted home service workers.
        </p>
      </div>

      <div className="my-10 sm:ml-2 sm:my-0 xl:mx-6">
        <p className="font-bold text-white sm:text-sm lg:text-base mb-2 mt-4 sm:mt-2">
          More Information
        </p>

        <div>
          <a
            href="http://faq.goodwork.ph"
            className="block py-1 text-white text-opacity-75 hover:text-opacity-100"
          >
            <p className="sm:text-sm">Blog</p>
          </a>

          <Link
            to={ROUTES.faq}
            className="block py-1 text-white text-opacity-75 hover:text-opacity-100"
          >
            <p className="sm:text-sm">FAQ</p>
          </Link>

          <Link
            to={ROUTES.termsAndConditions}
            className="block py-1 text-white text-opacity-75 hover:text-opacity-100"
          >
            <p className="sm:text-sm">Terms & Conditions</p>
          </Link>

          <Link
            to={ROUTES.privacyPolicyPage}
            className="block py-1 text-white text-opacity-75 hover:text-opacity-100"
          >
            <p className="sm:text-sm">Privacy Policy</p>
          </Link>

          <Link
            to={ROUTES.merchantPrivacyPolicy}
            className="block py-1 text-white text-opacity-75 hover:text-opacity-100"
          >
            <p className="sm:text-sm">Merchant Privacy Policy</p>
          </Link>

          <Link to={ROUTES.aboutUs} className="hidden"></Link>
        </div>
      </div>

      <div className="my-10 sm:my-0">
        <p className="font-bold text-white mb-2 mt-4 sm:mt-2 sm:text-sm lg:text-base">
          Available on
        </p>
        {storeLinks.map((store) => (
          <a href={store.url} className="block" key={store.url}>
            <img
              src={store.imageSource}
              alt={store.altText}
              className="w-32 mb-2 "
            />
          </a>
        ))}
      </div>

      <div>
        <p className="font-bold text-white mb-2 mt-4 sm:mt-2 sm:text-sm lg:text-base">
          Stay Connected
        </p>

        <a href="https://www.facebook.com/Goodwork.ph/">
          <img
            src={require('assets/images/facebook-icon.svg')}
            alt="Facebook"
            className="h-10 w-10 object-contain object-center inline-block"
          />
        </a>

        <a href="https://www.instagram.com/goodwork.ph/">
          <img
            src={require('assets/images/instagram-icon.svg')}
            alt="Instagram"
            className="h-10 w-10 object-contain object-center inline-block ml-2"
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
