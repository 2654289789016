import React from 'react';

const PerksSection = () => {
  return (
    <div className="container mx-auto md:flex flex-wrap py-6 md:py-10">
      <div className="flex-1 text-center m-8 md:m-6">
        <img
          className="w-24 inline-block"
          src={require('assets/images/phone_cleaning.png')}
          alt="Hand holding phone"
        />
        <h1 className="text-center text-blue-900 font-bold leading-tight text-xl my-2">
          Book your service
        </h1>
        <h3 className="text-center text-gray-600 max-w-xs mx-auto">
          Select from our popular list of home services like home cleaning,
          laundry delivery, and <br className="hidden xxs:inline xs:hidden" />
          beauty services.
        </h3>
      </div>
      <div className="flex-1 text-center m-8 md:m-6">
        <img
          className="w-24 h-auto inline-block"
          src={require('assets/images/cleaning.png')}
          alt="Woman with face mask and a mop"
        />
        <h1 className="text-center text-blue-900 font-bold leading-tight text-xl my-2">
          Enjoy service
        </h1>
        <h3 className="text-center text-gray-600 max-w-xs mx-auto">
          All our service providers are fully trained and verified. Our average
          customer <br className="xxs:hidden xs:inline lg:hidden xl:inline" />
          rating is 4.7!
        </h3>
      </div>
      <div className="flex-1 text-center m-8 md:m-6">
        <img
          className="w-24 h-auto inline-block"
          src={require('assets/images/hotel.png')}
          alt="Building with 5 stars on top"
        />
        <h1 className="text-center text-blue-900 font-bold leading-tight text-xl my-2">
          Earn rewards & <br className="xs:hidden md:inline lg:hidden" />
          book again
        </h1>
        <h3 className="text-center text-gray-600 max-w-xs mx-auto">
          Earn a free service after your 10th service! You can always book with
          your favorite <br className="xs:hidden" />
          service provider.
        </h3>
      </div>
    </div>
  );
};

export default PerksSection;
