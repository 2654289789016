import React from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';
import 'swiper/swiper-bundle.min.css';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

import './Reviews.css';

SwiperCore.use([Navigation, Pagination]);

const ReviewsCarousel = () => {
  const reviews = [
    {
      id: 0,
      imagePath: require('assets/images/nails-1.jpg'),
      review: `She's accommodating. Her tools look very neat. Magaan ang kamay niya, no pain! Thank you!`,
      reviewedBy: 'Lovely C.',
      altText: 'Manicure',
    },
    {
      id: 1,
      imagePath: require('assets/images/laundry-1.jpg'),
      review:
        'Rider assigned was very polite. Quality of work is excellent. Clothes are clean and smells fresh.',
      reviewedBy: 'Flor E.',
      altText:
        'Woman wearing facemask, holding bag of laundry, giving a thumbs up',
    },
    {
      id: 2,
      imagePath: require('assets/images/cleaning-1.jpg'),
      review:
        'Super friendly and she really made my room squeaky clean! Till the next, keep up your great work, ate!',
      reviewedBy: 'J. Orcine',
      altText: 'A cleaner waering PPE cleaning a mirror in a bathroom',
    },
    {
      id: 3,
      imagePath: require('assets/images/nails-2.jpg'),
      review: `She's so nice and really good. Me and even my friend is full praised with how she handled our 4 months no mani/pedi nails. 🤣 Good job Ate.`,
      reviewedBy: 'A. Florentino',
      altText: 'Pedicure',
    },
    {
      id: 4,
      imagePath: require('assets/images/laundry-2.jpg'),
      review:
        'My second time to try it - and impressed with the consistency of the quality of the service and how courteous the staff is. I like the fact that  it seems he is assigned to my area. It adds to the level of confidence and and reliability to the service.',
      reviewedBy: 'K. Zita',
      altText: 'Woman wearing a facemask hading over a bag of laundry to a man',
    },
    {
      id: 5,
      imagePath: require('assets/images/cleaning-2.jpg'),
      review:
        'I really loved their service - the room was super clean and the person who cleaned my unit was super trustworthy! 🙂',
      reviewedBy: 'M. Buvelle',
      altText: 'Woman holding a spray bottle cleaning the counter in kitchen',
    },
  ];

  return (
    <div className="light-container m-auto px-6 py-10 md:py-16">
      <h2 className="text-goodwork-navyblue font-bold text-center mb-6 text-lg sm:text-xl lg:text-2xl">
        Trusted by 50,000+ <br className="xs:hidden sm:hidden" />
        Satisfied Customers <br />
        in the Philippines
      </h2>
      <div className="max-w-lg md:max-w-3xl mx-auto">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          autoHeight
          className="rounded-lg shadow-lg inline-block bg-white"
        >
          {reviews.map((item, index) => (
            <SwiperSlide key={index} className="inline-block">
              <div className="md:grid md:grid-cols-3">
                <img
                  src={item.imagePath}
                  alt={item.altText}
                  className="review-img w-screen h-56 sm:h-64 md:h-full inline-block object-cover object-center col-span-1"
                />
                <div className="self-center col-span-2 m-2 sm:m-4 md:m-10 text-center md:text-left">
                  <div className="max-w-md mx-auto">
                    <p className="sm:text-lg px-4 pt-4 pb-2 md:pb-4 text-gray-600 ">
                      &ldquo;{item.review}&rdquo;
                    </p>

                    <div className="flex-col pb-10 px-4 md:pb-8 md:px-0 md:ml-4">
                      <img
                        src={require('assets/images/colored-facebook-logo.png')}
                        alt="Facebook f logo"
                        className="w-6 h-6 mr-2 inline-block object-cover object-center"
                      />
                      <p className="text-xs sm:text-sm inline-block text-gray-600 font-semibold">
                        Facebook review from {item.reviewedBy}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ReviewsCarousel;
