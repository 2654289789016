const storeLinks = [
  {
    url: 'https://play.google.com/store/apps/details?id=ph.goodwork',
    imageSource: require('assets/images/google-play-badge.png'),
    altText: 'Google Play Logo',
  },
  {
    url: 'https://apps.apple.com/ph/app/goodwork-ph/id1444626379',
    imageSource: require('assets/images/app-store-badge.svg'),
    altText: 'App Store Logo',
  },
  {
    url: 'https://appgallery1.huawei.com/#/app/C101835513',
    imageSource: require('assets/images/app-gallery-badge.png'),
    altText: 'App Gallery Logo',
  },
];

export default storeLinks;
